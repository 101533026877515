.footer-container {
    width: 100%;
    padding: 20px;
    background-color: rgba(92, 107, 115, 0.06);
}

.icons-container {
    display: flex;
    margin: auto;
    width: fit-content;
}

.icons-container > a {
    margin-right: 18px;
    color: black;
}

.icons-container > a > i:hover {
    cursor: pointer;
    background-color: white;
    box-shadow: 5px 10px rgba(92, 107, 115, 0.06);
}