body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-container {
  width: 100vw;
  height: fit-content;
  padding: 75px 80px;
}

.primary-text {
  font-weight: bold;
  font-size: 14px;
  color: #FF8484;
  margin-bottom: 4px;
}

.secondary-text {
  font-weight: normal;
  font-size: 18px;
  font-weight: 500;
  color: #00171F;
  margin-bottom: 32px;
}